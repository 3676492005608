
  import { loadEnabled } from '@/api/system/appConfig';
  import JtPagination from '@/components/JtPagination/index.vue';
  import { Component,Vue, Watch } from 'vue-property-decorator'
  import JtDatePicker from '@/components/JtDatePicker/index.vue';
  import { loadTasksTypes } from '@/api/task/tasks';
  import { loadPage } from '@/api/task/tasksReceive';
    
  
  @Component({
      name: 'TasksList',
      components: {
        JtPagination,
        JtDatePicker
      }
  })
  export default class extends Vue {
    isEdit = false
    dialogUpdateTaskVisible = false
    listLoading = true
    total = 0
    list :any = []
    appconfigs :any = []
    taskTypes :any = []
    listQuery = {
        appId: null,
        type: null,
        rewardType: null,
        keyword: null,
        startTime: null,
        endTime: null,
        current: 1,
        size: 10
    }
    updateRow = {}
  
    mounted() {
        this.getList()
        this.getTaskTypes()
        this.getAppconfigs()
    }
    //初始加载数据
    handleFilter() {
      this.listQuery.current = 1
      this.getList()
    }
    
    //加载应用信息
    getAppconfigs(){
        loadEnabled().then(res => {
            this.appconfigs = res.data
        })
    }
    
    //加载任务类型信息
    getTaskTypes(){
        loadTasksTypes().then(res => {
            this.taskTypes = res.data
        })
    }

    //加载api数据
    getList(){
      this.listLoading = true
      loadPage(this.listQuery).then(response => {
          this.list = response.data.rows
          this.total = response.data.total
          // Just to simulate the time of the request
          setTimeout(() => {
            this.listLoading = false
          }, 500)
        }).catch(()=>{
          this.listLoading = false
        })
    }
  
    resetSearch(){
      this.listQuery.appId = null
      this.listQuery.type = null
      this.listQuery.keyword = null
      this.listQuery.rewardType = null
      this.listQuery.startTime = null
      this.listQuery.endTime = null
    }
  }
  