import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface taskReceiveByTypeParam {
    type?:any
}

export interface taskReceiveParam {
    taskId?:any
}

export interface loadPageParam {
    current?:any
    size?:any
    appId?:any
    rewardType?:any
    startTime?:any
    endTime?:any
    keyword?:any
    type?:any
}
// -------接口列------

/**
 * [后台]加载用户任务奖励领取记录
 * @notes 
 */
export function loadPage(param:loadPageParam) {
    return get('/task/tasksReceive/loadPage', param);
}

/**
 * [APP]每日任务奖励领取(taskId)
 * @notes 
 */
export function taskReceive(param:taskReceiveParam) {
    return get('/task/tasksReceive/taskReceive', param);
}

/**
 * [APP]每日任务奖励领取(类型)
 * @notes 统一类型下多任务，依次完成
 */
export function taskReceiveByType(param:taskReceiveByTypeParam) {
    return get('/task/tasksReceive/taskReceiveByType', param);
}

